import React, { useEffect, useState } from 'react'
import { tns } from "tiny-slider/src/tiny-slider"
import 'tiny-slider/dist/tiny-slider.css'
import './GlobalSliderSettings.scss'
import SliderItem from './SliderItem'
import axios from 'axios'
import ContentLoader from '../ContentLoader/ContentLoader'

const TrendingSlider = () => {
    const [trending, setTrending] = useState([])
    const [contentLoaded, setContentLoaded] = useState(false)




    const getTrending = async () => {
        const query = { 'function': 'getTrending', 'items': 10};
        const res = await axios.post(`${process.env.GATSBY_API_FETCH_POST}`, query)
        setTrending(res.data)

        setContentLoaded(!contentLoaded)

    }

    useEffect(() => {
        var slider = tns({
            container: '.my-slider',
            items: 1,
            nav: true,
            dots: true,
            mouseDrag: true,
            autoplay: true,
            arrowKeys: false,
            speed: 1000,
            gutter: 30,
            controls: false,
            controlsPosition: "bottom",
            navPosition: "bottom",
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',

        });
    }, [contentLoaded])


    useEffect(() => {



        getTrending();



    }, [])




    return (
        <>
            <div class="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="my-slider">

                            {trending && trending.map((item, index) => {
                                return (

                                    <SliderItem id={index} post={item} />


                                )
                            })
                        }


                        </div>
                    </div>
                    <div className="pb-4">

                    </div>
                </div>
            </div>
        </>
    )
}

export default TrendingSlider
                    /* <div className="tns_slider_btn">
<button>ager</button>
<button>picher</button>
</div>
*/
