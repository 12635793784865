// extracted by mini-css-extract-plugin
export var post_entry = "postslider-module--post_entry--1ESeY";
export var thumbnail = "postslider-module--thumbnail--CgtZT";
export var content = "postslider-module--content--iqp8d";
export var category = "postslider-module--category--2lUug";
export var date = "postslider-module--date--3pt0C";
export var post_heading = "postslider-module--post_heading--38kns";
export var post_text = "postslider-module--post_text--2oMmz";
export var author_pic = "postslider-module--author_pic--2av1Q";
export var author_container = "postslider-module--author_container--1qDin";
export var author_text = "postslider-module--author_text--23U7C";