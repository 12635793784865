// extracted by mini-css-extract-plugin
export var tnsControls = "TrendingSlider-module--tns-controls--2WqHv";
export var slider_item = "TrendingSlider-module--slider_item--12ifR";
export var content = "TrendingSlider-module--content--8Ense";
export var post_thumbnail = "TrendingSlider-module--post_thumbnail--2YL6W";
export var category = "TrendingSlider-module--category--3wwhN";
export var date = "TrendingSlider-module--date--aSh4q";
export var post_heading = "TrendingSlider-module--post_heading--2DViJ";
export var post_text = "TrendingSlider-module--post_text--37zEh";
export var author_pic = "TrendingSlider-module--author_pic--369ki";
export var author_container = "TrendingSlider-module--author_container--1oPuL";
export var author_text = "TrendingSlider-module--author_text--3IKkK";