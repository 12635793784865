import React from 'react'
import Layout from '../Layout/Layout'
import TrendingSlider from '../Components/TrendingSlider/TrendingSlider'
import Heading from '../Components/Heading/Heading'
import RecentPosts from '../Components/RecentPosts/RecentPosts'
import PopularPost from '../Components/PopularPost/PopularPost'
import PostByCategory from '../Components/PostByCategory/PostByCategory'
import { Helmet } from 'react-helmet'
const index = () => {
  return (
    <>
      {/* gatsby seo */}
      <Helmet>
           <title>Homepage | Blogs | News</title>
            <meta name="title" content="Homepage | Blogs | News" />
            <meta name="description" content="A collection of sourced information from various categories is gathered in one location. Get efficient information about the latest trending topics to refresh your mind on a daily basis with updated news about technology, world affairs, current affairs, tutorials, and so on." />
            <meta name="keywords" content="diptanu chakraborty, blog, news site, tripura news, india news, world news, diptnc, best news website in tripura" />
            <meta name="robots" content="index, follow" />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="1 days" />
            <meta name="author" content="Diptanu chakraborty" />
      
            <meta property="og:title" content="Homepage | Blogs | News" />
            <meta property="og:description" content="A collection of sourced information from various categories is gathered in one location. Get efficient information about the latest trending topics to refresh your mind on a daily basis with updated news about technology, world affairs, current affairs, tutorials, and so on." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://blogs.diptanuchakraborty.in" />
            <meta property="og:image" content="https://cdn.diptanuchakraborty.in/diptanuchakraborty_in/assets/seo/og/og_asset.jpg" />

            <meta property="og:image:alt" content="Diptanu chakraborty" />
            <meta property="og:site_name" content="Blogs | Diptanuchakraborty.in" />
            <meta property="og:locale" content="en_US" />
            
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:description" content="A collection of sourced information from various categories is gathered in one location. Get efficient information about the latest trending topics to refresh your mind on a daily basis with updated news about technology, world affairs, current affairs, tutorials, and so on." />
            <meta name="twitter:title" content="Homepage | Blogs | News" />
            <meta name="twitter:site" content="@diptnc" />
            <meta name="twitter:image" content="https://cdn.diptanuchakraborty.in/diptanuchakraborty_in/assets/seo/og/og_asset.jpg" />

            <link rel="canonical" href="https://blogs.diptanuchakraborty.in" />
            <link rel="alternate" href="https://blogs.diptanuchakraborty.in" hreflang="en" />
            <link rel="alternate" href="https://blogs.diptanuchakraborty.in" hreflang="hi" />
            <link rel="alternate" href="https://blogs.diptanuchakraborty.in" hreflang="bn" />
            <link rel="alternate" href="https://blogs.diptanuchakraborty.in" hreflang="ta" />
            <link rel="alternate" href="https://blogs.diptanuchakraborty.in" hreflang="te" />
            <link rel="alternate" href="https://blogs.diptanuchakraborty.in" hreflang="ml" />
            <link rel="alternate" href="https://blogs.diptanuchakraborty.in" hreflang="mr" />


         









        </Helmet>

      <Layout>

   
        <Heading text="Trending" position="center"></Heading>
        <TrendingSlider></TrendingSlider>
        <Heading text="Recent Posts" position="center"></Heading>
        <RecentPosts id={0}></RecentPosts>
        <Heading text="Most Popular Posts" position="center"></Heading>
        <PopularPost></PopularPost>
        <Heading text="Posts By Category" position="center"></Heading>
        <PostByCategory></PostByCategory>

      </Layout>
    </>
  )
}

export default index
