// extracted by mini-css-extract-plugin
export var post_entry = "categorypost-module--post_entry--2OFA2";
export var inner = "categorypost-module--inner--xha8j";
export var thumbnail = "categorypost-module--thumbnail--1A33j";
export var content = "categorypost-module--content--3VsoT";
export var category = "categorypost-module--category--DhnfE";
export var date = "categorypost-module--date--2vYhs";
export var post_heading = "categorypost-module--post_heading--E5InD";
export var post_text = "categorypost-module--post_text--1DKMx";
export var author_pic = "categorypost-module--author_pic--1RHPx";
export var author_container = "categorypost-module--author_container--3mWzb";
export var author_text = "categorypost-module--author_text--3XOZw";