// extracted by mini-css-extract-plugin
export var post_entry = "SmallCards-module--post_entry--1-0IW";
export var thumbnail = "SmallCards-module--thumbnail--3q5A4";
export var content = "SmallCards-module--content--3ywpu";
export var category = "SmallCards-module--category--35wba";
export var date = "SmallCards-module--date--2d4cv";
export var post_heading = "SmallCards-module--post_heading--2DAEx";
export var post_text = "SmallCards-module--post_text--wBG0R";
export var author_pic = "SmallCards-module--author_pic--2zrFQ";
export var author_container = "SmallCards-module--author_container--1hE9a";
export var author_text = "SmallCards-module--author_text--3DU6B";